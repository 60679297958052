import { MessageDiv, StyledArticle } from '../styles';

import { Layout } from '../components/Layout';
import React from 'react';
import { StyledH2 } from '../styles/common';
import { graphql } from 'gatsby';
import { SEO } from '../components/SEO';
export const Head = () => (
	<SEO 
		title='Ogłoszenia'
		description='Ogłoszenia.'
	/>);
const OgloszeniaPage = ({ data }: any) => {
	const { allMarkdownRemark } = data;
	const { edges } = allMarkdownRemark;
	return (
		<Layout>
			{edges.map(({ node }: any) => {
				const { id, html, frontmatter } = node;
				const { title, color } = frontmatter;
				return (
					<StyledArticle color={color} key={id}>
						<StyledH2 as="h1" color={color}>
							{title}
						</StyledH2>
						<MessageDiv dangerouslySetInnerHTML={{ __html: html }} />
					</StyledArticle>
				);
			})}
		</Layout>
	);
};
export default OgloszeniaPage;

export const pageQuery = graphql`
	query {
		allMarkdownRemark(filter: { frontmatter: { menu: { eq: "ogloszenia" } } }) {
			edges {
				node {
					id
					html
					frontmatter {
						slug
						title
						color
					}
				}
			}
		}
	}
`;
